<template>
  <div class="personalData">
    <div class="bgwhite mt15 shadow radius5">
      <el-tabs v-model="activeName" @tab-click="handleClick" class="pad20">
        <el-tab-pane label="个人信息" name="first">
          <el-form
            :model="form"
            :rules="rules"
            ref="form"
            style="padding: 20px"
            label-width="90px"
          >
            <el-form-item label="昵称" prop="userName">
              <el-input
                style="width: 50%"
                v-model="form.userName"
                placeholder="请输入昵称"
              ></el-input>
            </el-form-item>
            <el-form-item label="头像" prop="portrait">
              <el-upload
                class="avatar-uploader"
                ref="upload"
                accept=".jpg, .JPG, png, .PNG , .jpeg, .JPEG"
                :data="uploadData"
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="uploadSuccess"
                :before-upload="beforeUpload"
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="性别" prop="sex">
              <el-radio-group v-model="form.sex">
                <el-radio label="男"></el-radio>
                <el-radio label="女"></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="真实姓名" prop="name">
              <el-input
                style="width: 50%"
                v-model="form.name"
                placeholder="请输入姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="常用邮箱" prop="mail">
              <el-input
                style="width: 50%"
                v-model="form.mail"
                placeholder="请输入邮箱"
              ></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-button type="primary" @click="onSubmit">保存</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="密码设置" name="second">
          <el-form
            :model="form1"
            :rules="rules1"
            ref="form1"
            style="padding: 20px"
            label-width="90px"
          >
            <el-form-item label="旧密码" prop="oldPwd">
              <el-input
                show-password
                style="width: 50%"
                v-model="form1.oldPwd"
                placeholder="请输入密码"
              ></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="newPwd">
              <el-input
                show-password
                style="width: 50%"
                v-model="form1.newPwd"
                placeholder="请输入新密码"
              ></el-input>
            </el-form-item>
            <el-form-item label="确认新密码" prop="checknewPwd">
              <el-input
                show-password
                style="width: 50%"
                v-model="form1.checknewPwd"
                placeholder="请再次输入新密码"
              ></el-input>
            </el-form-item>
            <el-form-item label="" style="margin-left: 40%">
              <el-button type="text" @click="forgotPwd">忘记密码?</el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="passwordChange">保存</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <!-- 找回密码 -->
      <el-dialog :title="title" :visible.sync="passwordVisible" width="400px">
        <el-form :model="passwordform" ref="passwordform" style="padding: 20px">
          <div v-if="forgotPassword">
            <el-form-item prop="phoneNumber">
              <el-input
                v-model="passwordform.phoneNumber"
                placeholder="请输入手机号码"
                prefix-icon="el-icon-user"
              ></el-input>
            </el-form-item>
            <el-form-item prop="identifyCode" style="margin-bottom: 5px">
              <el-row :gutter="8" type="flex">
                <el-col :span="16">
                  <el-input
                    v-model="passwordform.identifyCode"
                    placeholder="请输入图形验证码"
                  ></el-input>
                </el-col>
                <el-col :span="8" style="height: 48px">
                  <img
                    :src="codeImg"
                    @click="refreshImage"
                    height="48"
                    width="112"
                    style="
                      cursor: pointer;
                      width: 100%;
                      border-radius: 4px;
                      height: 90%;
                    "
                  />
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item prop="verificationCode" style="margin-bottom: 5px">
              <el-row :gutter="8" type="flex">
                <el-col :span="16">
                  <el-input
                    v-model="passwordform.verificationCode"
                    placeholder="请输入短信验证码"
                  ></el-input>
                </el-col>
                <el-col :span="8" style="height: 48px">
                  <el-button type="success" plain @click="passwordClick">
                    {{ sendCodeLabel }}
                  </el-button>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                @click="loginForget"
                :loading="submitLoading"
                style="width: 100%"
                >确定</el-button
              >
            </el-form-item>
          </div>
          <!-- 设置新密码 -->
          <div v-if="newPassword">
            <el-form-item prop="password">
              <el-input
                show-password
                v-model="passwordform.password"
                placeholder="请输入密码"
                prefix-icon="el-icon-goods"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <div style="display: flex; justify-content: flex-end">
                <el-button
                  type="primary"
                  @click="previousStep"
                  style="width: 25%"
                  >上一步</el-button
                >
                <el-button
                  type="primary"
                  @click="loginForgetNext"
                  :loading="submitLoading"
                  style="width: 25%"
                  >确定</el-button
                >
              </div>
            </el-form-item>
          </div>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
var timer = require("@/assets/timer.js");
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else if (value === this.form1.oldPwd) {
        callback(new Error("两次输入密码一致!"));
      } else {
        callback();
      }
    };
    var validatePass3 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入新密码"));
      } else if (value !== this.form1.newPwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      token: localStorage.getItem("pc_token"),
      value: JSON.parse(localStorage.getItem("pc_data")) || {},
      uploadUrl: this.$http.httpConfig + this.$http.uploadImg,
      uploadData: {
        relative: "headPortrait",
        appCode: "headPortrait",
      },
      title: "找回密码",
      activeName: "first",
      form1: {
        oldPwd: "",
        newPwd: "",
        checknewPwd: "",
      },
      form: {
        userName: "",
        sex: "",
        name: "",
        mail: "",
      },
      passwordform: {
        phoneNumber: "",
        identifyCode: "",
        verificationCode: "",
        password: "",
      },
      rules: {
        userName: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
        ],
        mail: [{ required: true, message: "请输入活动名称", trigger: "blur" }],
      },
      rules1: {
        oldPwd: [{ validator: validatePass, trigger: "blur" }],
        newPwd: [{ validator: validatePass2, trigger: "blur" }],
        checknewPwd: [{ validator: validatePass3, trigger: "blur" }],
      },
      imageUrl: "",
      codeImg: "", //图形验证码图片
      sendCodeLabel: "获取验证码",
      seconds: timer.length,
      interval: null,
      t: "",
      passwordVisible: false,
      forgotPassword: true, //忘记密码
      newPassword: false, //设置新密码
      sendCodeBtnDisabled: false,
      submitLoading: false,
    };
  },
  methods: {
    // 修改密码保存
    passwordChange() {
      alert(1);
      this.$refs.form1.validate((valid) => {
        alert(2);
        if (valid) {
          alert(3);
          this.$api
            .updatePwdByOldPwd({
              token: this.token,
              oldPwd: this.form1.oldPwd,
              pwd: this.form1.newPwd,
            })
            .then((res) => {
              alert(4);
              if (res.code == 10000) {
                this.$message.success("密码修改成功");
                this.$refs.form1.resetFields();
              }
            });
        }
      });
    },
    // 忘记密码按钮
    forgotPwd() {
      setTimeout(() => {
        this.refreshImage();
      }, 100);
      this.passwordVisible = true;
    },
    // 切换验证码
    refreshImage() {
      this.t = this.createUniqueId(8).join("-");
      let codeImg = this.$http.httpConfig + this.$http.authImage + this.t;
      this.codeImg = codeImg;
    },
    //获取图形验证码
    createUniqueId(n) {
      let random = function () {
        // 生成10-12位不等的字符串
        return Number(Math.random().toString().substr(2)).toString(36); // 转换成十六进制
      };
      let arr = [];

      function createId() {
        var num = random();
        var _bool = false;
        arr.forEach((v) => {
          if (v === num) _bool = true;
        });
        if (_bool) {
          createId();
        } else {
          arr.push(num);
        }
      }

      let i = 0;
      while (i < n) {
        createId();
        i++;
      }
      return arr;
    },
    // 忘记密码获取短信验证码
    passwordClick() {
      let that = this;
      if (that.passwordform.phoneNumber == "") {
        this.$message({
          message: "请输入您的手机号码！",
          type: "warning",
        });
        return;
      } else if (that.passwordform.phoneNumber.length != 11) {
        this.$message({
          message: "请输入正确的手机号码！",
          type: "warning",
        });
        return;
      } else if (that.passwordform.identifyCode == "") {
        this.$message({
          message: "请输入图形验证码！",
          type: "warning",
        });
        return;
      }
      if (this.sendCodeBtnDisabled) {
        return;
      }
      // 禁用按钮点击
      that.sendCodeBtnDisabled = true;
      let data = {
        channelId: this.$http.channelId,
        mobilePhone: that.passwordform.phoneNumber,
        checkCode: that.passwordform.identifyCode,
        t: that.t,
        terminalName: "pc",
      };
      this.$api
        .getResetMessageCheckCode(data)
        .then((res) => {
          if (res.code == 10000) {
            this.$message.success("发送成功");
            this.sendCodeLabel = --this.seconds + "s后重发";
            // 启动以1s为步长的倒计时
            that.interval = setInterval(() => {
              timer.countdown(that);
            }, 1000);
          } else {
            that.sendCodeBtnDisabled = false;
            that.refreshImage();
            this.$message.error(data.msg);
          }
        })
        .catch((err) => {
          that.sendCodeBtnDisabled = false;
          that.refreshImage();
          that.$message.error("网络异常，请重试");
        });
    },
    // 忘记密码确定按钮
    loginForget() {
      this.$refs.passwordform.validate((valid) => {
        if (valid) {
          this.submitLoading = true;
          this.$api
            .checkPhoneCode({
              mobilePhone: this.passwordform.phoneNumber,
              phoneCode: this.passwordform.verificationCode,
              terminalName: "pc",
            })
            .then((res) => {
              if (res.code == 10000) {
                this.submitLoading = false;
                this.title = "设置新密码";
                this.newPassword = true;
                this.forgotPassword = false;
              }
            });
        }
      });
    },
    // 返回上一步
    previousStep() {
      this.$refs.passwordform.resetFields();
      this.newPassword = false;
      this.forgotPassword = true;
    },
    // 设置新密码确定按钮
    loginForgetNext() {
      this.$refs.passwordform.validate((valid) => {
        if (valid) {
          this.submitLoading = true;
          this.$api
            .updateUserPwd({
              channelId: this.$http.channelId,
              mobilePhone: this.passwordform.phoneNumber,
              pwd: this.passwordform.password,
            })
            .then((res) => {
              if (res.code == 10000) {
                this.$message.success("密码设置成功");
                this.passwordVisible = false;
              }
            });
        }
      });
    },
    handleClick(tab, event) {
      this.$refs.form1.resetFields();
    },
    uploadSuccess(response) {
      if (response.code === "10000") {
        this.imageUrl = response.filePreviewPathFull;
      }
    },
    reload() {
      location.reload();
    },
    beforeUpload(file) {
      if (file.size / 1024 / 1024 > 10) {
        this.$message.warning("文件大能超过10M");
        return false;
      }
    },
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const imgUrl = this.imageUrl || "";
          if (imgUrl === "") {
            this.$message.error("请上传头像！");
            return false;
          }
          const userName = this.form.userName || "";
          if (userName === "") {
            this.$message.error("请填写昵称");
            return false;
          }
          const mail = this.form.mail;
          if (mail === "") {
            this.$message.error("请填写邮箱");
            return false;
          }
          this.$api
            .updateMemberUser({
              channelId: this.$http.channelId,
              userNum: this.value.userNum || "",
              userName: this.form.userName,
              headPortrait: this.imageUrl,
              realName: this.form.name,
              cardNumber: this.value.cardNumber || "",
              email: this.form.mail,
              token: this.token,
            })
            .then((res) => {
              if (res.code == 10000) {
                this.$message.success("保存成功");
                const objForm = {
                  channelId: this.$http.channelId,
                  userNum: this.value.userNum || "",
                  userName: this.form.userName,
                  headPortrait: this.imageUrl,
                  realName: this.form.name,
                  cardNumber: this.value.cardNumber || "",
                  email: this.form.mail,
                  token: this.token,
                };
                let pcData = JSON.parse(localStorage.getItem("pc_data"))
                for (const key in objForm) {
                  pcData[key] = objForm[key]
                }
                localStorage.setItem('pc_data',JSON.stringify(pcData))
                this.reload();
                this.form = "";
                this.imageUrl = "";
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.personalData {
  margin-bottom: 50px;
}
.shadow {
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
.radius5 {
  border-radius: 5px;
}
.bgwhite {
  display: inline-block;
  background-color: #fff;
  width: 100%;
}
.mt15 {
  margin-top: 15px;
}
.pad20 {
  padding: 20px;
}
.avatar-uploader ::v-deep.el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader ::v-deep.el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.binding {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #666;
  cursor: pointer;
}
</style>